import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

export default function ControlledCarouselNew({ data, maindata }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setIsDataLoaded(true);
    }
  }, [data]);

  return (
    <>
      {/* Services */}
      <section id="home" style={{ padding: "0px" }}>
        {isDataLoaded && (
          <OwlCarousel
            items={1}
            slideBy={1}
            className="owl-theme"
            loop
            nav={false}
            dots={true}
            autoplay={true}
            responsive={{
              0: {
                // breakpoint from 0 up
                items: 1,
                margin: 10,
                // stagePadding: 10,
              },
              600: {
                // breakpoint from 600 up
                items: 1,
                margin: 20,
                // stagePadding: 20,
              },
              1000: {
                // breakpoint from 1000 up
                items: 1,
                margin: 30,
                // stagePadding: 30,
              },
              // Add more breakpoints as needed
            }}
          >
            {data.map((e, i) => (
              <div
                id="banner"
                style={{
                  backgroundImage: `url(${e.brImage})`,
                  height: "100vh",
                }}
                key={i}
              >
                <div className="caption-info">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 col-md-8 col-md-offset-2">
                        <div className="parallax-bg-red py-4">
                          <div className="caption-info-inner text-center">
                            <h1
                              className="animated fadeInDown"
                              style={{
                                color:
                                  maindata &&
                                    maindata["banner_font_color" + (i + 1)]
                                    ? maindata["banner_font_color" + (i + 1)]
                                    : "#fff",
                              }}
                            >
                              {e.brTitle}
                            </h1>
                            <p
                              className="animated fadeInUp"
                              style={{
                                color:
                                  maindata && maindata["banner_tagline" + (i + 1)]
                                    ? maindata["banner_tagline" + (i + 1)]
                                    : "#fff",
                                fontSize: "22px",
                              }}
                            >
                              {e.brSubtitle}
                              {e.showbnlogo == "yes" &&
                                <div className="BrandLogo">
                                  <img
                                    src={
                                      maindata && maindata.logo
                                        ? maindata.logo
                                        : "img/logo.png"
                                    }
                                    className="BannerLogoImg"
                                    alt="Logo"
                                  />
                                </div>
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              // <div className="service-list" key={e.id} onClick={()=>imgPopupHndler(e)}>
              //     <img src={e.image ? e.image : "img/services/2.jpg"} alt="" />
              //     <div className="service-content">
              //         <h3>{e.service_name}</h3>
              //         <p>{e.description}</p>
              //     </div>
              // </div>
            ))}
          </OwlCarousel>
        )}
      </section>
      {/* <Loginmodals opens={isopen} isClose={isClose}  /> */}
    </>
  );
}
